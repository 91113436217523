<template>
  <div
    class="external-ai-artists-v2 mb-8 d-flex flex-row justify-content-start"
    id="external_ai_artists_v2"
  >
    <div class="external-artists-left-bar me-10">
      <div class="card shadow-card charts-card">
        <div class="card-body">
          <div class="card-head">
            <span class="heading-sideBar"> Ai Catalogues</span>
            <hr />
          </div>
          <div class="slider-filters">
            <div class="card-sub-headings d-flex align-items-center">
              <div class="el-image icon stats-social-icon pb-1">
                <img
                  class="el-image__inner"
                  src="/media/svg/social-logos/spotify.svg"
                  alt=""
                />
              </div>
              <div class="sub-headings-sidebar m-lg-2">
                Weighted Catalog Age from Total
              </div>
            </div>
            <div>
              <el-slider
                v-model="weightedCatalogueAge"
                range
                :min="weightedCatalogueAgeMin"
                :max="weightedCatalogueAgeMax"
                @change="valuesChanged"
              />
              <div
                class="
                  pt-2
                  d-flex
                  justify-content-around
                  theme-v2-color
                  align-items-center
                "
              >
                <div class="range-values">
                  <!--                  {{ Number(weightedCatalogueAge[0]).toLocaleString() }}-->
                  <input
                    type="number"
                    class="form-control filter-inputs"
                    :min="weightedCatalogueAgeMin"
                    :max="weightedCatalogueAgeMax"
                    v-model="weightedCatalogAgeNum1"
                    @change="
                      weightedCatalogueAge = [
                        weightedCatalogAgeNum1,
                        weightedCatalogAgeNum2,
                      ]
                    "
                  />
                </div>
                <div class="fw-boldest ps-4 pe-4">-</div>
                <div class="range-values">
                  <!--                  {{ Number(weightedCatalogueAge[1]).toLocaleString() }}-->
                  <input
                    type="number"
                    class="form-control filter-inputs"
                    v-model="weightedCatalogAgeNum2"
                    :min="weightedCatalogueAgeMin"
                    :max="weightedCatalogueAgeMax"
                    @change="
                      weightedCatalogueAge = [
                        weightedCatalogAgeNum1,
                        weightedCatalogAgeNum2,
                      ]
                    "
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="card-sub-headings d-flex align-items-center">
              <div class="el-image icon stats-social-icon pb-1">
                <img
                  class="el-image__inner"
                  src="/media/svg/social-logos/spotify.svg"
                  alt=""
                />
              </div>
              <div class="sub-headings-sidebar m-lg-2">Yearly Revenue</div>
            </div>
            <div>
              <el-slider
                v-model="yearlyRevenue"
                range
                :min="yearlyRevenueMin"
                :max="yearlyRevenueMax"
                @change="valuesChanged"
              />
              <div
                class="
                  pt-2
                  d-flex
                  justify-content-around
                  theme-v2-color
                  align-items-center
                "
              >
                <div class="range-values">
                  <input
                    type="number"
                    class="form-control filter-inputs"
                    :min="yearlyRevenueMin"
                    :max="yearlyRevenueMax"
                    v-model="yearlyRevenueNum1"
                    @change="
                      yearlyRevenue = [yearlyRevenueNum1, yearlyRevenueNum2]
                    "
                  />
                </div>
                <div class="fw-boldest ps-4 pe-4">-</div>
                <div class="range-values">
                  <input
                    type="number"
                    class="form-control filter-inputs"
                    :min="yearlyRevenueMin"
                    :max="yearlyRevenueMax"
                    v-model="yearlyRevenueNum2"
                    @change="
                      yearlyRevenue = [yearlyRevenueNum1, yearlyRevenueNum2]
                    "
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="card-sub-headings d-flex align-items-center">
              <div class="el-image icon stats-social-icon pb-1">
                <img
                  class="el-image__inner"
                  src="/media/svg/social-logos/spotify.svg"
                  alt=""
                />
              </div>
              <div class="sub-headings-sidebar m-lg-2">36M Growth</div>
            </div>
            <div>
              <el-slider
                v-model="growth"
                range
                :min="growthMin"
                :max="growthMax"
                @change="valuesChanged"
              />
              <div
                class="
                  pt-2
                  d-flex
                  justify-content-around
                  theme-v2-color
                  align-items-center
                "
              >
                <div class="range-values">
                  <input
                    type="number"
                    class="form-control filter-inputs"
                    :min="growthMin"
                    :max="growthMax"
                    v-model="growthNum1"
                    @change="growth = [growthNum1, growthNum2]"
                  />
                </div>
                <div class="fw-boldest ps-4 pe-4">-</div>
                <div class="range-values">
                  <input
                    type="number"
                    class="form-control filter-inputs"
                    :min="growthMin"
                    :max="growthMax"
                    v-model="growthNum2"
                    @change="growth = [growthNum1, growthNum2]"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="card-sub-headings d-flex align-items-center">
              <div class="sub-headings-sidebar m-lg-2">
                Only show results with
                {{ socialMediaSwitch ? "social media data" : "" }}
                {{ socialMediaSwitch && emailSwitch ? " and " : "" }}
                {{ emailSwitch ? "email" : "" }}
              </div>
            </div>
            <div class="social-media-switch ms-2 mt-4">
              <el-switch
                v-model="socialMediaSwitch"
                size="large"
                @change="valuesChanged"
              />
              <span
                class="switch-text ms-4"
                @click="valuesChanged('socialMediaSwitch')"
                >Social Media Data</span
              >
            </div>
            <div class="email-switch ms-2 mt-4">
              <el-switch
                v-model="emailSwitch"
                size="large"
                @change="valuesChanged"
              />
              <span
                class="switch-text ms-4"
                @click="valuesChanged('emailSwitch')"
                >Email</span
              >
            </div>
            <hr />
            <div class="genres mb-2">
              <div class="sub-headings-sidebar m-lg-2">Genre</div>
              <div class="search-bar">
                <input
                  class="form-control form-control-solid me-3 search-field"
                  placeholder="Search genre"
                  v-model="search"
                />
                <svg
                  class="search-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z"
                  />
                </svg>
              </div>
              <div
                v-for="(genre, index) in filteredGenres"
                :key="index"
                class="genre-checkboxes ms-2 pt-1"
              >
                <el-radio-group v-model="checkedGenre">
                  <el-radio :label="genre.name" @change="genreChanged"
                    >{{ genre.name }}
                  </el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="external-artist-content w-100 overflow-hidden">
      <CardToolbar
        v-loading="loading"
        class="crawled-artists"
        :title="'Artists listed in ' + checkedGenre"
        :show-toolbar-buttons="true"
        :fontStyle="true"
        :margin="true"
        :padding="false"
        :shadowClass="true"
      >
        <template v-slot:toolbar>
          <el-checkbox
            style="margin-right: 1rem"
            :disabled="artistsList.length === 0"
            v-model="allSelected"
            @change="selectAllClicked"
            label="Select all on page"
            size="large"
          ></el-checkbox>
          <CardHeaderButtonAI
            :disabled="!selectedArtists.length"
            @buttonClicked="addBatchToCRM"
            >+CRM
          </CardHeaderButtonAI>
          <CardHeaderButtonAI solid :disabled="!selectedArtists.length"
            >Update
          </CardHeaderButtonAI>
          <CardHeaderButtonAI solid @click="routeToSandboxGenrePage">
            Genre Profile
          </CardHeaderButtonAI>
        </template>
        <template v-slot:body>
          <el-table
            @sort-change="sortResults"
            style="width: 100%"
            row-key="id"
            :fit="true"
            table-layout="fixed"
            stripe
            header-cell-class-name="table-header-text"
            :data="artistsList"
            :row-class-name="tableRowClassName"
            :cell-class-name="tableCellClassName"
          >
            <el-table-column
              sortable="custom"
              label="Name"
              prop="name"
              fixed
              width="200"
            >
              <template #default="scope">
                {{ scope.row.name ? scope.row.name : "" }}
                <span
                  :class="[
                    'svg-icon svg-icon-checkmark',
                    scope.row.isExistInHubspot ? 'svg-icon-primary' : '',
                  ]"
                >
                  <inline-svg src="/media/icons/duotune/general/gen026.svg" />
                </span>
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              label="Monthly Listeners"
              prop="spotifyListenersTotal"
              align="right"
              width="200"
            >
              <template #default="scope">
                {{
                  formatNumberIntoHumanizeForm(scope.row.spotifyListenersTotal)
                }}
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              label="Growth 24 months"
              prop="listenersGrowth24months"
              align="right"
              width="200"
            >
              <template #default="scope">
                {{
                  formatNumberIntoHumanizeForm(
                    scope.row.listenersGrowth24months
                  )
                }}
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              label="Growth 36 months"
              prop="listenersGrowth36months"
              align="right"
              width="200"
            >
              <template #default="scope">
                {{
                  formatNumberIntoHumanizeForm(
                    scope.row.listenersGrowth36months
                  )
                }}
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              label="Revenues"
              prop="revenue"
              align="right"
              width="200"
            >
              <template #default="scope">
                {{ formatNumberIntoHumanizeForm(scope.row.revenue) }}
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              label="Fanbase"
              prop="fanbase"
              align="right"
              width="200"
            >
              <template #default="scope">
                {{ formatNumberIntoHumanizeForm(scope.row.fanbase) }}
              </template>
            </el-table-column>
            <!--            <el-table-column-->
            <!--              sortable="custom"-->
            <!--              label="# Playlists"-->
            <!--              prop="playlistTotals"-->
            <!--              align="right"-->
            <!--              width="200"-->
            <!--            >-->
            <!--              <template #default="scope">-->
            <!--                {{ formatNumberIntoHumanizeForm(scope.row.playlistTotals) }}-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <el-table-column
              sortable="custom"
              label="Playlist Reach"
              prop="playlistReach"
              align="right"
              width="200"
            >
              <template #default="scope">
                {{ formatNumberIntoHumanizeForm(scope.row.playlistReach) }}
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              label="Average Catalog Age"
              prop="averageCatalogAge"
              align="right"
              width="220"
            >
              <template #default="scope">
                {{ formatNumberIntoHumanizeForm(scope.row.averageCatalogAge) }}
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              label="Weighted Catalog Age"
              prop="weightedCatalogAge"
              align="right"
              width="230"
            >
              <template #default="scope">
                {{ formatNumberIntoHumanizeForm(scope.row.weightedCatalogAge) }}
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              label="Weighted Catalog Age From Total"
              prop="weightedCatalogAgeFromTotal"
              align="right"
              width="260"
            >
              <template #default="scope">
                {{
                  formatNumberIntoHumanizeForm(
                    scope.row.weightedCatalogAgeFromTotal
                  )
                }}
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              label="% Played Songs > 3 Years"
              prop="percentagePlayedSongsGreaterThan3Years"
              align="right"
              width="230"
            >
              <template #default="scope">
                {{
                  formatNumberIntoHumanizeForm(
                    scope.row.percentagePlayedSongsGreaterThan3Years
                  )
                }}
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              label="Daily Streams from Total (All Songs)"
              prop="dailyStreamsFromTotalByAllSongsOlderThan3Years"
              align="right"
              width="270"
            >
              <template #default="scope">
                {{
                  formatNumberIntoHumanizeForm(
                    scope.row.dailyStreamsFromTotalByAllSongsOlderThan3Years
                  )
                }}
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              label="Daily Streams from Total (Main Songs)"
              prop="dailyStreamsFromTotalByTypeMainOlderThan3Years"
              align="right"
              width="300"
            >
              <template #default="scope">
                {{
                  formatNumberIntoHumanizeForm(
                    scope.row.dailyStreamsFromTotalByTypeMainOlderThan3Years
                  )
                }}
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              label="Daily Streams from Total (All Songs - Corrected)"
              prop="dailyStreamsFromTotalCorrectedByAllSongsOlderThan3Years"
              align="right"
              width="350"
            >
              <template #default="scope">
                {{
                  formatNumberIntoHumanizeForm(
                    scope.row
                      .dailyStreamsFromTotalCorrectedByAllSongsOlderThan3Years
                  )
                }}
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              label="Daily Streams from Total (Main Songs - Corrected)"
              prop="dailyStreamsFromTotalCorrectedByTypeMainOlderThan3Years"
              align="right"
              width="370"
            >
              <template #default="scope">
                {{
                  formatNumberIntoHumanizeForm(
                    scope.row
                      .dailyStreamsFromTotalCorrectedByTypeMainOlderThan3Years
                  )
                }}
              </template>
            </el-table-column>
            <el-table-column label="Email" align="right" width="200">
              <template #default="scope">
                <a
                  href="javascript:void(0)"
                  v-if="
                    (scope.row.googleScrapedEmails &&
                      scope.row.googleScrapedEmails.length > 0) ||
                    (scope.row.chartMetricEmails &&
                      scope.row.chartMetricEmails.length > 0)
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    class="icon-style email-icon"
                  >
                    <path
                      d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.021 17.824c-3.907 0-6.021-2.438-6.021-5.586 0-3.363 2.381-6.062 6.638-6.062 3.107 0 5.362 2.019 5.362 4.801 0 4.356-5.165 5.506-4.906 3.021-.354.555-.927 1.177-2.026 1.177-1.257 0-2.04-.92-2.04-2.403 0-2.222 1.461-4.1 3.19-4.1.829 0 1.399.438 1.638 1.11l.232-.816h1.169c-.122.416-1.161 4.264-1.161 4.264-.323 1.333.675 1.356 1.562.648 1.665-1.29 1.75-4.664-.499-6.071-2.411-1.445-7.897-.551-7.897 4.347 0 2.806 1.976 4.691 4.914 4.691 1.719 0 2.771-.465 3.648-.974l.588.849c-.856.482-2.231 1.104-4.391 1.104zm-1.172-7.153c-.357.67-.588 1.538-.588 2.212 0 1.805 1.761 1.816 2.626.12.356-.697.586-1.586.586-2.265 0-1.458-1.748-1.717-2.624-.067z"
                    />
                  </svg>
                </a>
              </template>
            </el-table-column>
            <el-table-column label="Social Sites" align="right" width="300">
              <template #default="scope">
                <a
                  :href="
                    scope.row.chartMetricContacts.facebook
                      ? scope.row.chartMetricContacts.facebook
                      : scope.row.googleScrapedContacts.facebook
                  "
                  target="_blank"
                  v-if="
                    scope.row.chartMetricContacts.facebook ||
                    (scope.row.googleScrapedContacts &&
                      scope.row.googleScrapedContacts.facebook)
                  "
                >
                  <svg
                    class="icon-style facebook-icon"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  >
                    <path
                      d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0 4.5c-3.314 0-6 2.487-6 5.556 0 1.748.872 3.307 2.236 4.326v2.118l2.043-1.121c.545.151 1.123.232 1.721.232 3.314 0 6-2.487 6-5.555 0-3.069-2.686-5.556-6-5.556zm.596 7.481l-1.528-1.629-2.981 1.629 3.279-3.481 1.566 1.63 2.944-1.63-3.28 3.481z"
                    />
                  </svg>
                </a>
                <a
                  :href="
                    scope.row.chartMetricContacts.instagram
                      ? scope.row.chartMetricContacts.instagram
                      : scope.row.googleScrapedContacts.instagram
                  "
                  target="_blank"
                  v-if="
                    scope.row.chartMetricContacts.instagram ||
                    (scope.row.googleScrapedContacts &&
                      scope.row.googleScrapedContacts.instagram)
                  "
                >
                  <svg
                    class="icon-style instagram-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 7.082c1.602 0 1.792.006 2.425.035 1.627.074 2.385.845 2.46 2.459.028.633.034.822.034 2.424s-.006 1.792-.034 2.424c-.075 1.613-.832 2.386-2.46 2.46-.633.028-.822.035-2.425.035-1.602 0-1.792-.006-2.424-.035-1.63-.075-2.385-.849-2.46-2.46-.028-.632-.035-.822-.035-2.424s.007-1.792.035-2.424c.074-1.615.832-2.386 2.46-2.46.632-.029.822-.034 2.424-.034zm0-1.082c-1.63 0-1.833.007-2.474.037-2.18.1-3.39 1.309-3.49 3.489-.029.641-.036.845-.036 2.474 0 1.63.007 1.834.036 2.474.1 2.179 1.31 3.39 3.49 3.49.641.029.844.036 2.474.036 1.63 0 1.834-.007 2.475-.036 2.176-.1 3.391-1.309 3.489-3.49.029-.64.036-.844.036-2.474 0-1.629-.007-1.833-.036-2.474-.098-2.177-1.309-3.39-3.489-3.489-.641-.03-.845-.037-2.475-.037zm0 2.919c-1.701 0-3.081 1.379-3.081 3.081s1.38 3.081 3.081 3.081 3.081-1.379 3.081-3.081c0-1.701-1.38-3.081-3.081-3.081zm0 5.081c-1.105 0-2-.895-2-2 0-1.104.895-2 2-2 1.104 0 2.001.895 2.001 2s-.897 2-2.001 2zm3.202-5.922c-.397 0-.72.322-.72.72 0 .397.322.72.72.72.398 0 .721-.322.721-.72 0-.398-.322-.72-.721-.72z"
                    />
                  </svg>
                </a>
                <a
                  :href="
                    scope.row.chartMetricContacts.soundcloud
                      ? scope.row.chartMetricContacts.soundcloud
                      : scope.row.googleScrapedContacts.soundcloud
                  "
                  target="_blank"
                  v-if="
                    scope.row.chartMetricContacts.soundcloud ||
                    (scope.row.googleScrapedContacts &&
                      scope.row.googleScrapedContacts.soundcloud)
                  "
                >
                  <svg
                    class="icon-style soundcloud-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.709 15h-.541v-4.4c.167-.126.347-.234.541-.309v4.709zm1.625 0h.543v-5.031c-.125.145-.24.299-.337.468l-.206-.101v4.664zm-1.082 0h.541v-4.826c-.274-.043-.337-.027-.541-.005v4.831zm-2.168 0h.543v-3.829c-.17.25-.302.529-.375.836l-.168-.099v3.092zm-1.625-2.863c-.329.298-.541.738-.541 1.236 0 .499.212.938.541 1.238v-2.474zm10.227-.366c-.11-1.548-1.302-2.771-2.772-2.771-.554 0-1.064.179-1.496.479v5.521h4.924c.87 0 1.576-.76 1.576-1.695 0-1.218-1.174-2.056-2.232-1.534zm-9.143-.016c-.163-.014-.285-.017-.541.066v3.103c.241.078.345.076.541.076v-3.245z"
                    />
                  </svg>
                </a>
                <a
                  :href="
                    scope.row.chartMetricContacts.twitter
                      ? scope.row.chartMetricContacts.twitter
                      : scope.row.googleScrapedContacts.twitter
                  "
                  target="_blank"
                  v-if="
                    scope.row.chartMetricContacts.twitter ||
                    (scope.row.googleScrapedContacts &&
                      scope.row.googleScrapedContacts.twitter)
                  "
                >
                  <svg
                    class="icon-style twitter-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.5 8.778c-.441.196-.916.328-1.414.388.509-.305.898-.787 1.083-1.362-.476.282-1.003.487-1.564.597-.448-.479-1.089-.778-1.796-.778-1.59 0-2.758 1.483-2.399 3.023-2.045-.103-3.86-1.083-5.074-2.572-.645 1.106-.334 2.554.762 3.287-.403-.013-.782-.124-1.114-.308-.027 1.14.791 2.207 1.975 2.445-.346.094-.726.116-1.112.042.313.978 1.224 1.689 2.3 1.709-1.037.812-2.34 1.175-3.647 1.021 1.09.699 2.383 1.106 3.773 1.106 4.572 0 7.154-3.861 6.998-7.324.482-.346.899-.78 1.229-1.274z"
                    />
                  </svg>
                </a>
                <a
                  :href="
                    scope.row.chartMetricContacts.youtube
                      ? scope.row.chartMetricContacts.youtube
                      : scope.row.googleScrapedContacts.youtube
                  "
                  target="_blank"
                  v-if="
                    scope.row.chartMetricContacts.youtube ||
                    (scope.row.googleScrapedContacts &&
                      scope.row.googleScrapedContacts.youtube)
                  "
                >
                  <svg
                    class="icon-style youtube-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M16.23 7.102c-2.002-.136-6.462-.135-8.461 0-2.165.148-2.419 1.456-2.436 4.898.017 3.436.27 4.75 2.437 4.898 1.999.135 6.459.136 8.461 0 2.165-.148 2.42-1.457 2.437-4.898-.018-3.436-.271-4.75-2.438-4.898zm-6.23 7.12v-4.444l4.778 2.218-4.778 2.226zm2-12.222c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z"
                    />
                  </svg>
                </a>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              fixed="right"
              width="100"
              label="Action"
            >
              <template #default="scope">
                <ArtistMenuComponent
                  v-if="scope.row.name"
                  :artist="{
                    name: scope.row.name,
                    uuid: scope.row.uuid,
                    spotifyID: scope.row.spotifyID,
                  }"
                  :buttons="[
                    'analytics',
                    'discover',
                    'crawl',
                    scope.row && 'artistDetails',
                    'aiSandbox',
                    'artistNewDesign',
                  ]"
                  :showFollowButton="false"
                ></ArtistMenuComponent>
              </template>
            </el-table-column>
            <el-table-column
              :key="checkBoxKey"
              fixed="right"
              width="80"
              label="Select"
              align="right"
            >
              <template #default="scope">
                <el-checkbox
                  :model-value="selectedArtists.includes(scope.row.spotifyID)"
                  :disabled="
                    !selectedArtists.includes(scope.row.spotifyID) &&
                    selectedArtists.length > 49
                  "
                  @change="checkBoxValueChanged($event, scope.row.spotifyID)"
                  label=""
                  size="large"
                ></el-checkbox>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="mt-5"
            v-model:currentPage="localPagination.offset"
            v-model:page-size="localPagination.limit"
            :page-sizes="[5, 10, 20, 50, 100]"
            :small="true"
            layout="total, sizes, prev, pager, next"
            :total="localPagination.total"
            @size-change="paginationChanged"
            @current-change="paginationChanged"
          >
          </el-pagination>
        </template>
      </CardToolbar>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { getChartmetricGenres } from "@/api/genreResult";
import { getArtistByGenreFromExternalSearch } from "@/api/genreResult";
import {
  getSocialSitesFromChartMetricArray,
  getSocialSitesFromGoogleScrapedContacts,
} from "@/utils/staticHelper";
import CardToolbar from "@/common/components/CardToolbar";
import { paginate, sortArray } from "@/aiBetaBench/helpers";
import ArtistMenuComponent from "@/artists/components/ArtistMenuComponent";
import CardHeaderButtonAI from "@/aiBetaBench/components/CardHeaderButtonAI";
import { addArtistsToCRM } from "@/api/hubspot";
import { ElNotification } from "element-plus";
import { watch } from "vue";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default {
  name: "ExternalAiArtists",
  components: { CardToolbar, CardHeaderButtonAI, ArtistMenuComponent },
  setup() {
    const loading = ref(false);
    const search = ref(null);
    const weightedCatalogueAge = ref([1500, 15000]);
    const weightedCatalogueAgeMin = ref(1500);
    const weightedCatalogueAgeMax = ref(15000);
    const weightedCatalogAgeNum1 = ref(1500);
    const weightedCatalogAgeNum2 = ref(15000);
    const yearlyRevenue = ref([1500, 1000000]);
    const yearlyRevenueMin = ref(0);
    const yearlyRevenueMax = ref(1000000);
    const yearlyRevenueNum1 = ref(1500);
    const yearlyRevenueNum2 = ref(1000000);
    const growth = ref([-5, 100]);
    const growthMin = ref(-5);
    const growthMax = ref(100);
    const growthNum1 = ref(-5);
    const growthNum2 = ref(100);
    const socialMediaSwitch = ref(true);
    const emailSwitch = ref(false);
    const genres = ref([]);
    const filteredGenres = ref([]);
    const checkedGenre = ref("");
    const artistsList = ref([]);
    const allSelected = ref(false);
    const artistsListWithoutPagination = ref([]);
    const selectedArtists = ref([]);
    const checkBoxKey = ref(0);
    const localPagination = ref({
      offset: 1,
      limit: 10,
      total: 0,
    });
    const filteredArtists = ref([]);
    const isFilterActive = ref(true);
    const sortBy = ref(null);
    const sortDir = ref(null);
    onMounted(async () => {
      try {
        loading.value = true;
        let { data } = await getChartmetricGenres();
        genres.value = data;
        filteredList();
        if (genres.value.length > 0) {
          checkedGenre.value = genres.value[0].name;
          await getArtistByGenre();
        } else {
          loading.value = false;
        }
        data = [];
      } catch (e) {
        console.error(e);
      }
    });
    const getArtistByGenre = async () => {
      try {
        loading.value = true;
        let { data } = await getArtistByGenreFromExternalSearch(
          checkedGenre.value
        );
        artistsList.value = [];
        artistsListWithoutPagination.value = [];
        for (const artist of data) {
          const payload = {
            name: artist.name
              ? artist.name.charAt(0).toUpperCase() + artist.name.slice(1)
              : "",
            spotifyID: artist.spotifyID ? artist.spotifyID : null,
            uuid: artist.soundchartsUUID ? artist.soundchartsUUID : null,
            averageCatalogAge: artist.averageCatalogAge
              ? artist.averageCatalogAge
              : 0,
            weightedCatalogAge: artist.weightedCatalogAge
              ? artist.weightedCatalogAge
              : 0,
            weightedCatalogAgeFromTotal: artist.weightedCatalogAgeFromTotal
              ? artist.weightedCatalogAgeFromTotal
              : 0,
            percentagePlayedSongsGreaterThan3Years:
              artist.percentagePlayedSongsGreaterThan3Years
                ? artist.percentagePlayedSongsGreaterThan3Years
                : 0,
            dailyStreamsFromTotalByAllSongsOlderThan3Years:
              artist.dailyStreamsFromTotalByAllSongsOlderThan3Years
                ? artist.dailyStreamsFromTotalByAllSongsOlderThan3Years
                : 0,
            dailyStreamsFromTotalByTypeMainOlderThan3Years:
              artist.dailyStreamsFromTotalByTypeMainOlderThan3Years
                ? artist.dailyStreamsFromTotalByTypeMainOlderThan3Years
                : 0,
            dailyStreamsFromTotalCorrectedByAllSongsOlderThan3Years:
              artist.dailyStreamsFromTotalCorrectedByAllSongsOlderThan3Years
                ? artist.dailyStreamsFromTotalCorrectedByAllSongsOlderThan3Years
                : 0,
            dailyStreamsFromTotalCorrectedByTypeMainOlderThan3Years:
              artist.dailyStreamsFromTotalCorrectedByTypeMainOlderThan3Years
                ? artist.dailyStreamsFromTotalCorrectedByTypeMainOlderThan3Years
                : 0,
            spotifyListenersTotal: artist.chartmetricSpotifyListenersTotal
              ? artist.chartmetricSpotifyListenersTotal
              : 0,
            listenersGrowth24months:
              artist.chartmetricSpotifyListenersGrowth &&
              artist.chartmetricSpotifyListenersGrowth["24Months"]
                ? artist.chartmetricSpotifyListenersGrowth["24Months"]
                : 0,
            listenersGrowth36months:
              artist.chartmetricSpotifyListenersGrowth &&
              artist.chartmetricSpotifyListenersGrowth["36Months"]
                ? artist.chartmetricSpotifyListenersGrowth["36Months"]
                : 0,
            // playlistTotals:
            //   artist.playlistMetrics && artist.playlistMetrics.total
            //     ? artist.playlistMetrics.total
            //     : 0,
            revenue: artist.chartmetricRevenue ? artist.chartmetricRevenue : 0,
            fanbase: artist.chartmetricFanbase ? artist.chartmetricFanbase : 0,
            playlistReach: artist.playlistReach ? artist.playlistReach : 0,
            googleScrapedContacts: artist.googleScrapedContacts
              ? getSocialSitesFromGoogleScrapedContacts(
                  artist.googleScrapedContacts
                )
              : {},
            googleScrapedEmails:
              artist.googleScrapedContacts &&
              artist.googleScrapedContacts.emails
                ? artist.googleScrapedContacts.emails
                : [],
            chartMetricEmails:
              artist.chartmetricContacts && artist.chartmetricContacts.emails
                ? artist.chartmetricContacts.emails
                : [],
            chartMetricContacts: getSocialSitesFromChartMetricArray(
              artist.chartmetricContacts && artist.chartmetricContacts.contacts
                ? artist.chartmetricContacts.contacts
                : {}
            ),
            isExistInHubspot: artist.hubspotID ? artist.hubspotID : "",
          };
          artistsListWithoutPagination.value.push(payload);
        }
        const minCatalogueValue = getMinValues(
          artistsListWithoutPagination.value,
          "weightedCatalogAgeFromTotal"
        );
        weightedCatalogueAgeMin.value =
          minCatalogueValue > 1500 ? 1500 : minCatalogueValue;

        const maxCatalogueValue = getMaxValues(
          artistsListWithoutPagination.value,
          "weightedCatalogAgeFromTotal"
        );
        weightedCatalogueAgeMax.value =
          maxCatalogueValue > 15000 ? maxCatalogueValue : 15000;

        const minRevenue = getMinValues(
          artistsListWithoutPagination.value,
          "revenue"
        );
        yearlyRevenueMin.value = minRevenue < 1500 ? minRevenue : 1500;
        const maxRevenue = getMaxValues(
          artistsListWithoutPagination.value,
          "revenue"
        );
        yearlyRevenueMax.value = maxRevenue > 1000000 ? maxRevenue : 1000000;

        const minGrowth = getMinValues(
          artistsListWithoutPagination.value,
          "listenersGrowth36months"
        );
        growthMin.value = minGrowth < -5 ? minGrowth : -5;
        const maxGrowth = getMaxValues(
          artistsListWithoutPagination.value,
          "listenersGrowth36months"
        );
        growthMax.value = maxGrowth > 100 ? maxGrowth : 100;
        data = [];
        filterArtistsArray();
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };
    const filteredList = (value) => {
      if (value) {
        filteredGenres.value = genres.value.filter((genre) =>
          genre.name.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        filteredGenres.value = genres.value;
      }
    };
    const getMaxValues = (array, key) => {
      if (array) {
        return Math.max(
          ...array.map((o) => (o[key] ? Number(o[key]) : Number(0)))
        );
      }
      return 100;
    };
    const getMinValues = (array, key) => {
      if (array) {
        return Math.min(
          ...array.map((o) => (o[key] ? Number(o[key]) : Number(0)))
        );
      }
      return 0;
    };
    const filterArtistsArray = () => {
      filteredArtists.value = artistsListWithoutPagination.value.filter(
        function (artist) {
          return (
            artist["weightedCatalogAgeFromTotal"] >=
              weightedCatalogueAge.value[0] &&
            artist["weightedCatalogAgeFromTotal"] <=
              weightedCatalogueAge.value[1] &&
            artist["listenersGrowth36months"] >= growth.value[0] &&
            artist["listenersGrowth36months"] <= growth.value[1] &&
            artist["revenue"] >= yearlyRevenue.value[0] &&
            artist["revenue"] <= yearlyRevenue.value[1] &&
            (socialMediaSwitch.value
              ? Object.keys(artist["chartMetricContacts"]).length > 0 ||
                Object.keys(artist["googleScrapedContacts"]).length > 0
              : artist["googleScrapedContacts"] ||
                artist["chartMetricContacts"]) &&
            (emailSwitch.value
              ? artist["chartMetricEmails"].length > 0 ||
                artist["googleScrapedEmails"].length > 0
              : artist["chartMetricEmails"] || artist["googleScrapedEmails"])
          );
        }
      );
      localPagination.value.total = filteredArtists.value.length;
      localPagination.value.offset = 1;
      localPagination.value.limit = 10;
      paginationChanged();
    };
    const paginationChanged = () => {
      allSelected.value = false;
      if (isFilterActive.value) {
        artistsList.value = filteredArtists.value;
      } else {
        artistsList.value = artistsListWithoutPagination.value;
      }
      artistsList.value = paginate(
        artistsList.value,
        localPagination.value.limit,
        localPagination.value.offset
      );
    };
    const checkBoxValueChanged = (event, id) => {
      allSelected.value = false;
      if (event && !selectedArtists.value.includes(id)) {
        selectedArtists.value.push(id);
      } else if (!event) {
        const index = selectedArtists.value.indexOf(id);
        if (index > -1) {
          selectedArtists.value.splice(index, 1);
        }
      }
    };
    const selectAllClicked = (value) => {
      if (value) {
        selectedArtists.value = artistsList.value.map(
          (object) => object.spotifyID
        );
      } else {
        selectedArtists.value = [];
      }
    };
    const sortAllDataIfRequired = (array) => {
      if (sortBy.value && sortDir.value) {
        return sortArray(sortBy.value, sortDir.value, array);
      } else {
        return array;
      }
    };
    const sortResults = ({ prop, order }) => {
      sortBy.value = prop;
      sortDir.value = order;
      artistsListWithoutPagination.value = sortAllDataIfRequired(
        JSON.parse(JSON.stringify(artistsListWithoutPagination.value))
      );
      paginationChanged();
    };
    const tableRowClassName = ({ rowIndex }) => {
      return rowIndex % 2 === 0
        ? "table-data-text"
        : "table-data-text striped-table-row";
    };
    const tableCellClassName = ({ columnIndex }) => {
      if (columnIndex === 0 || columnIndex === 1) {
        return "table-column-ellipses";
      } else {
        return "";
      }
    };
    const genreChanged = () => {
      getArtistByGenre();
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    };
    const valuesChanged = (val) => {
      weightedCatalogAgeNum1.value = weightedCatalogueAge.value[0];
      weightedCatalogAgeNum2.value = weightedCatalogueAge.value[1];
      yearlyRevenueNum1.value = yearlyRevenue.value[0];
      yearlyRevenueNum2.value = yearlyRevenue.value[1];
      growthNum1.value = growth.value[0];
      growthNum2.value = growth.value[1];
      if (val) {
        if (val === "socialMediaSwitch") {
          socialMediaSwitch.value = !socialMediaSwitch.value;
        } else if (val === "emailSwitch") {
          emailSwitch.value = !emailSwitch.value;
        }
      }
      filterArtistsArray();
    };
    const routeToSandboxGenrePage = () => {
      window.open(
        `${process.env.VUE_APP_SANDBOX_GENRE_URI}/?genre=${checkedGenre.value}`,
        "_blank"
      );
    };
    const addBatchToCRM = async () => {
      if (selectedArtists.value.length) {
        loading.value = true;
        try {
          const mappedArtists = artistsList.value
            .filter((g) => selectedArtists.value.includes(g.spotifyID))
            .map((object) => {
              return {
                spotifyID: object.spotifyID,
              };
            });

          addArtistsToCRM(mappedArtists);
          checkBoxKey.value++;
          selectedArtists.value = [];
          allSelected.value = false;
          ElNotification({
            title: "Success",
            message: "Selected artists added to CRM!",
            type: "success",
          });
        } catch (e) {
          ElNotification({
            title: "Error",
            message: "Error adding to CRM, try again later",
            type: "error",
          });
        } finally {
          loading.value = false;
        }
      }
    };
    watch(
      () => search.value,
      (value) => {
        filteredList(value);
      }
    );
    return {
      loading,
      search,
      weightedCatalogueAge,
      weightedCatalogueAgeMin,
      weightedCatalogueAgeMax,
      yearlyRevenue,
      yearlyRevenueMin,
      yearlyRevenueMax,
      growth,
      growthMin,
      growthMax,
      socialMediaSwitch,
      emailSwitch,
      filteredGenres,
      checkedGenre,
      artistsList,
      artistsListWithoutPagination,
      allSelected,
      selectedArtists,
      checkBoxKey,
      localPagination,
      sortBy,
      sortDir,
      weightedCatalogAgeNum1,
      weightedCatalogAgeNum2,
      yearlyRevenueNum1,
      yearlyRevenueNum2,
      growthNum1,
      growthNum2,
      paginationChanged,
      selectAllClicked,
      checkBoxValueChanged,
      sortAllDataIfRequired,
      sortResults,
      tableRowClassName,
      tableCellClassName,
      routeToSandboxGenrePage,
      genreChanged,
      valuesChanged,
      addBatchToCRM,
      formatNumberIntoHumanizeForm,
    };
  },
};
</script>
<style scoped lang="scss">
.external-artists-left-bar {
  .charts-card {
    width: 300px;

    .card-body {
      padding: 1rem !important;

      .slider-filters {
        .card-sub-headings {
          font-size: 14px;
          color: #33647f !important;
        }

        .el-slider {
          padding: 0 6px;
        }

        .genres {
          input::placeholder {
            color: #33647f !important;
            font-size: 12px;
            font-weight: 400;
          }

          .search-bar {
            position: relative;
            width: -webkit-fill-available;
            padding-bottom: 1rem !important;
          }

          .search-field {
            padding: 0.75rem 1.5rem !important;
            line-height: 0 !important;
          }

          .search-icon {
            position: absolute;
            right: 4%;
            font-size: 24px;
            bottom: 42%;
            fill: #33647f;
          }

          .genre-checkboxes {
            .el-radio {
              color: black;
            }
          }
        }
      }
    }
  }
}

.range-values {
  background-color: aliceblue;
  //padding: 2px 16px;
}

.stats-social-icon {
  width: 25px;
  height: 25px;
}

.icon-style {
  margin-right: 4px;
  fill: gray;
}

.switch-text {
  font-weight: 600 !important;
  vertical-align: middle;
  cursor: pointer;
}

@media screen and (max-width: 1250px) {
  .external-artists-left-bar .charts-card {
    width: 250px;
  }
}

.filter-inputs {
  border: none;
  background-color: aliceblue;
  padding: 4px 16px !important;
  text-align: center;
  font-weight: 400;
  color: #33647f !important;
}
</style>
